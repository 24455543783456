// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Quill from "quill";
import * as Caml_option from "rescript/lib/es6/caml_option.js";
import QuillDelta from "quill-delta";
import * as Caml_js_exceptions from "rescript/lib/es6/caml_js_exceptions.js";

var Css = {};

function toString(x) {
  return JSON.stringify(x);
}

function fromString(x) {
  var json;
  try {
    json = JSON.parse(x);
  }
  catch (raw_error){
    var error = Caml_js_exceptions.internalToOCamlException(raw_error);
    return {
            TAG: "Error",
            _0: error
          };
  }
  return {
          TAG: "Ok",
          _0: new QuillDelta(json)
        };
}

var Delta = {
  toString: toString,
  fromString: fromString
};

var Quill$1 = {};

var resourceToolbarOptions = [
    [{"header": [false, 2, 3]}],
    ["bold", "italic", "underline", "link"],
    [{"align": []}],
    [{"list": "ordered"}, {"list": "bullet"}]
  ];

var blogPostToolbarOptions = [
    [{"header": [false, 2, 3]}],
    ["bold", "italic", "underline", "link", "image"],
    [{"align": []}],
    [{"list": "ordered"}, {"list": "bullet"}]
  ];

var countryToolbarOptions = [
    [{"header": [false, 2, 3]}],
    ["bold", "italic", "underline", "link"],
    [{"align": []}],
    [{"list": "ordered"}, {"list": "bullet"}]
  ];

var blogPostCategoryToolbarOptions = [
    ["bold", "italic", "underline", "link"]
  ];

var providerToolbarOptions = [
    [{"header": [false, 2, 3]}],
    ["bold", "italic", "underline"],
    [{"align": []}],
    [{"list": "ordered"}, {"list": "bullet"}]
  ];

function make(placeholder, readOnlyOpt, themeOpt, toolbar, keyboard, bounds, container) {
  var readOnly = readOnlyOpt !== undefined ? readOnlyOpt : false;
  var theme = themeOpt !== undefined ? themeOpt : "snow";
  return new Quill(container, {
              placeholder: placeholder,
              readOnly: readOnly,
              theme: theme,
              bounds: bounds !== undefined ? Caml_option.valFromOption(bounds) : null,
              modules: {
                toolbar: toolbar,
                keyboard: {
                  bindings: keyboard
                }
              }
            });
}

function isEmpty(x) {
  return x.getLength() <= 1;
}

function addBinding(x, key, cmd, handler) {
  x.addBinding({
        key: key,
        shortKey: cmd
      }, handler);
}

var Keyboard = {
  addBinding: addBinding
};

export {
  Css ,
  Delta ,
  Quill$1 as Quill,
  resourceToolbarOptions ,
  blogPostToolbarOptions ,
  countryToolbarOptions ,
  blogPostCategoryToolbarOptions ,
  providerToolbarOptions ,
  make ,
  isEmpty ,
  Keyboard ,
}
/*  Not a pure module */
