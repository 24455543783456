// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Quill from "../../../bindings/Quill.res.js";
import * as React from "react";
import * as Belt_Option from "rescript/lib/es6/belt_Option.js";
import * as Belt_Result from "rescript/lib/es6/belt_Result.js";
import * as Caml_option from "rescript/lib/es6/caml_option.js";
import QuillDelta from "quill-delta";
import * as JsxRuntime from "react/jsx-runtime";
import * as QuillCoreCss from "quill/dist/quill.core.css";
import * as QuillSnowCss from "quill/dist/quill.snow.css";

var Change = {};

function make() {
  return new QuillDelta();
}

function compose(state, change) {
  return state.compose(change);
}

function toString(x) {
  return Quill.Delta.toString(x);
}

function fromString(x) {
  return Belt_Result.map(Quill.Delta.fromString(x), (function (prim) {
                return prim;
              }));
}

var State = {
  Change: Change,
  make: make,
  compose: compose,
  toString: toString,
  fromString: fromString
};

var make$1 = React.forwardRef(function (props, ref) {
      return JsxRuntime.jsx("div", {
                  ref: Belt_Option.map((ref == null) ? undefined : Caml_option.some(ref), (function (prim) {
                          return prim;
                        }))
                });
    });

export {
  State ,
  make$1 as make,
}
/*  Not a pure module */
