// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Cx from "rescript-classnames/src/Cx.res.js";
import * as IconSelect from "../../icons/IconSelect.res.js";
import * as Belt_Option from "rescript/lib/es6/belt_Option.js";
import * as SelectScss from "./Select.scss";
import * as JsxRuntime from "react/jsx-runtime";

var css = SelectScss;

function Select(props) {
  var __iconColor = props.iconColor;
  var __iconSize = props.iconSize;
  var __wrapperClassName = props.wrapperClassName;
  var __autoFocus = props.autoFocus;
  var __className = props.className;
  var status = props.status;
  var __disabled = props.disabled;
  var value = props.value;
  var disabled = __disabled !== undefined ? __disabled : false;
  var className = __className !== undefined ? __className : "";
  var autoFocus = __autoFocus !== undefined ? __autoFocus : false;
  var wrapperClassName = __wrapperClassName !== undefined ? __wrapperClassName : "";
  var iconSize = __iconSize !== undefined ? __iconSize : "MD";
  var iconColor = __iconColor !== undefined ? __iconColor : "LighterGray";
  var tmp;
  tmp = status !== undefined && status !== "Valid" ? css.error : "";
  return JsxRuntime.jsxs("div", {
              children: [
                JsxRuntime.jsx("select", {
                      children: props.children,
                      ref: Belt_Option.map(props.setRef, (function (prim) {
                              return prim;
                            })),
                      className: Cx.cx([
                            css.select,
                            value !== "" ? css.value : css.placeholder,
                            tmp,
                            className
                          ]),
                      id: props.id,
                      autoFocus: autoFocus,
                      disabled: disabled,
                      type: "",
                      value: value,
                      onFocus: props.onFocus,
                      onBlur: props.onBlur,
                      onChange: props.onChange
                    }),
                JsxRuntime.jsx(IconSelect.make, {
                      size: iconSize,
                      color: iconColor,
                      className: css.icon
                    })
              ],
              className: Cx.cx([
                    css.wrapper,
                    wrapperClassName
                  ])
            });
}

function Select$Option(props) {
  return JsxRuntime.jsx("option", {
              children: props.children,
              value: props.value
            });
}

var $$Option = {
  make: Select$Option
};

function Select$DefaultOption(props) {
  var __selectable = props.selectable;
  var selectable = __selectable !== undefined ? __selectable : false;
  return JsxRuntime.jsx("option", {
              children: props.children,
              hidden: !selectable,
              default: true,
              disabled: !selectable,
              value: ""
            });
}

var DefaultOption = {
  make: Select$DefaultOption
};

var make = Select;

export {
  css ,
  make ,
  $$Option ,
  DefaultOption ,
}
/* css Not a pure module */
