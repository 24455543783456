// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Cx from "rescript-classnames/src/Cx.res.js";
import * as ColScss from "./Col.scss";
import * as JsxRuntime from "react/jsx-runtime";

var css = ColScss;

function getClassNameMd(md) {
  switch (md) {
    case 1 :
        return css.colMd1;
    case 2 :
        return css.colMd2;
    case 3 :
        return css.colMd3;
    case 4 :
        return css.colMd4;
    case 5 :
        return css.colMd5;
    case 6 :
        return css.colMd6;
    case 7 :
        return css.colMd7;
    case 8 :
        return css.colMd8;
    case 9 :
        return css.colMd9;
    case 10 :
        return css.colMd10;
    case 11 :
        return css.colMd11;
    default:
      return css.colMd12;
  }
}

function getClassNameSm(sm) {
  switch (sm) {
    case 1 :
        return css.colSm1;
    case 2 :
        return css.colSm2;
    case 3 :
        return css.colSm3;
    case 4 :
        return css.colSm4;
    case 5 :
        return css.colSm5;
    case 6 :
        return css.colSm6;
    case 7 :
        return css.colSm7;
    case 8 :
        return css.colSm8;
    case 9 :
        return css.colSm9;
    case 10 :
        return css.colSm10;
    case 11 :
        return css.colSm11;
    default:
      return css.colSm12;
  }
}

function getClassNameLg(lg) {
  switch (lg) {
    case 1 :
        return css.colLg1;
    case 2 :
        return css.colLg2;
    case 3 :
        return css.colLg3;
    case 4 :
        return css.colLg4;
    case 5 :
        return css.colLg5;
    case 6 :
        return css.colLg6;
    case 7 :
        return css.colLg7;
    case 8 :
        return css.colLg8;
    case 9 :
        return css.colLg9;
    case 10 :
        return css.colLg10;
    case 11 :
        return css.colLg11;
    default:
      return css.colLg12;
  }
}

function checkToggles(toggle) {
  if (toggle === "up") {
    return true;
  } else {
    return toggle === "down";
  }
}

function getHiddenMd(hiddenMd) {
  if (hiddenMd === "up") {
    return css.colMdHiddenUp;
  } else if (hiddenMd === "down") {
    return css.colMdHiddenDown;
  } else {
    return "";
  }
}

function getHiddenSm(hiddenSm) {
  if (hiddenSm === "up") {
    return css.colSmHiddenUp;
  } else if (hiddenSm === "down") {
    return css.colSmHiddenDown;
  } else {
    return "";
  }
}

function getHiddenLg(hiddenLg) {
  if (hiddenLg === "up") {
    return css.colLgHiddenUp;
  } else if (hiddenLg === "down") {
    return css.colLgHiddenDown;
  } else {
    return "";
  }
}

function Col(props) {
  var __hiddenLg = props.hiddenLg;
  var __hiddenSm = props.hiddenSm;
  var __hiddenMd = props.hiddenMd;
  var __sm = props.sm;
  var __lg = props.lg;
  var __md = props.md;
  var __className = props.className;
  var className = __className !== undefined ? __className : "";
  var md = __md !== undefined ? __md : 0;
  var lg = __lg !== undefined ? __lg : 0;
  var sm = __sm !== undefined ? __sm : 0;
  var hiddenMd = __hiddenMd !== undefined ? __hiddenMd : "";
  var hiddenSm = __hiddenSm !== undefined ? __hiddenSm : "";
  var hiddenLg = __hiddenLg !== undefined ? __hiddenLg : "";
  return JsxRuntime.jsx("div", {
              children: props.children,
              className: Cx.cx([
                    css.col,
                    className,
                    md > 0 ? getClassNameMd(md) : "",
                    lg > 0 ? getClassNameLg(lg) : "",
                    sm > 0 ? getClassNameSm(sm) : "",
                    checkToggles(hiddenSm) ? getHiddenSm(hiddenSm) : "",
                    checkToggles(hiddenMd) ? getHiddenMd(hiddenMd) : "",
                    checkToggles(hiddenLg) ? getHiddenLg(hiddenLg) : ""
                  ])
            });
}

var make = Col;

export {
  css ,
  getClassNameMd ,
  getClassNameSm ,
  getClassNameLg ,
  checkToggles ,
  getHiddenMd ,
  getHiddenSm ,
  getHiddenLg ,
  make ,
}
/* css Not a pure module */
