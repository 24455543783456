// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Cx from "rescript-classnames/src/Cx.res.js";
import * as GridScss from "./Grid.scss";
import * as JsxRuntime from "react/jsx-runtime";

var css = GridScss;

function Grid(props) {
  var __className = props.className;
  var className = __className !== undefined ? __className : "";
  return JsxRuntime.jsx("div", {
              children: props.children,
              className: Cx.cx([
                    css.grid,
                    className
                  ])
            });
}

var make = Grid;

export {
  css ,
  make ,
}
/* css Not a pure module */
