// Generated by ReScript, PLEASE EDIT WITH CARE

import * as ID from "../../libs/ID.res.js";

function category(category$1) {
  return "/resources/" + category$1.toLowerCase();
}

function show(slug, categorySlug) {
  return "/resources/" + (categorySlug + ("/" + slug));
}

function leadsCsv(resourceId) {
  return "/resources/" + (ID.toString(resourceId) + "/leads.csv");
}

function downloadThanks(filename) {
  return "/thanks-for-download?file=" + filename;
}

function edit(resourceId) {
  return "/dashboard/resources/edit/" + ID.toString(resourceId);
}

function editResourceProviders(resourceId) {
  return "/dashboard/resources/" + ID.toString(resourceId) + "/providers";
}

var Dashboard = {
  index: "/dashboard/resources",
  analytics: "/dashboard/resources/analytics",
  $$new: "/dashboard/resources/new",
  edit: edit,
  editResourceProviders: editResourceProviders
};

var resourcesIndex = "/resources";

export {
  resourcesIndex ,
  category ,
  show ,
  leadsCsv ,
  downloadThanks ,
  Dashboard ,
}
/* ID Not a pure module */
